<template>
  <div class="product">
    <div class="header">
      <div class="title">
        <span>往期优质</span>
      </div>
    </div>
    <div class="banner">
      <div class="prev" @click="prev">
        <i class="el-icon-arrow-left"></i>
      </div>

      <swiper
        ref="mySwiper"
        :options="swiperOptions"
        :key="swiperOptions.swiperKey"
      >
        <swiper-slide v-for="(item, index) in bannerList" :key="index">
          <div class="box">
            <div class="box__top">
              <img
                v-if="item.apparatusFileId"
                :src="item.apparatusFileId"
                alt=""
              />
              <img
                v-if="!item.apparatusFileId"
                src="../assets/test.png"
                alt=""
              />
            </div>
            <div class="box__bottom">
              <div class="content">
                <div class="title">
                  {{ item.apparatusType }}{{ item.apparatusName }}
                </div>
                <div class="text">
                  <div class="reportText">报告数量:{{ item.applyNum }}</div>
                  <div class="reportText">报告人数:{{ item.commentNum }}</div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div class="next" @click="next">
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="line"></div>
    <div class="content">
      <div>
        <div class="instrument_c_btn baseBtn">
          <div class="titleBox">
            <button class="title" @click="assessList(0)">全部</button>
          </div>

          <div class="text" @click="assessList(1)">申请中()</div>
          <div class="text" @click="assessList(2)">即将开始()</div>
          <div class="text" @click="assessList(5)">体验中()</div>
          <div class="text" @click="assessList(3)">已结束()</div>
        </div>
      </div>
      <release-layout
        :data="instrumentList"
        :row="4"
        :id="'layout1'"
        :to="path"
        :type="'check'"
      ></release-layout>
      <div class="button">
        <button @click="more" v-if="total > instrumentList.length">
          加载更多
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide, directive } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import releaseLayout from "../components/releaseLayout.vue";
import api from "../api/api";
import check from "../api/check";
export default {
  data() {
    return {
      path: {
        url: "/check/product/info",
      },
      swiperOptions: {
        swiperKey: Math.random().toString(36).substr(2),
        slidesPerView: 4,
        spaceBetween: 20,
        navigation: {
          nextEl: ".prev",
          prevEl: ".next",
        },
      },
      bannerList: [],
      instrumentList: [],
      pages: 1,
      state: "",
      total: "",
    };
  },
  components: {
    swiper,
    swiperSlide,
    releaseLayout,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  directives: {
    swiper: directive,
  },
  created() {
    this.indexTopAssess();
    this.assessList(0);
  },
  mounted() {},
  watch: {
    bannerList() {
      this.$nextTick(() => {
        if (this.bannerList.length < this.swiperOptions.slidesPerView) {
          this.swiperOptions.slidesPerView = this.bannerList.length;
          this.swiperOptions.swiperKey = Math.random().toString(36).substr(2);
        }
      });
    },
    pages() {
      this.assessList(this.state, "more");
    },
  },
  methods: {
    prev() {
      this.swiper.slidePrev();
    },
    next() {
      this.swiper.slideNext();
    },
    indexTopAssess() {
      let data = {
        type: "review",
      };
      api.indexTopAssess(data).then((res) => {
        this.bannerList = res.data.data.data;
      });
    },
    assessList(state, mode) {
      this.state = state;
      let data = {
        state: state,
        page: this.pages,
      };
      check.assessList(data).then((res) => {
        let newList = [];
        let list = res.data.data.data;
        this.total = res.data.data.total;
        list.forEach((item) => {
          let data = {
            id: item.id,
            // username: item.author,
            // time: item.createTime,
            name: item.apparatusName,
            url: item.apparatusFileId,
            newsTitle: item.newsName,
            order: item.order,
            quantify: item.quantify,
            applyNum: item.applyNum,
            condition: item.condition,

            // viewCount: item.newsViews,
            // commentCount: item.commentNum,
            // likeCount: item.total,
          };
          newList.push(data);
        });
        this.instrumentList = mode
          ? [...this.instrumentList, ...newList]
          : newList;
      });
    },
    more() {
      this.pages++;
    },
  },
};
</script>
<style lang="scss" scoped>
.product {
  .header {
    .title {
      padding-bottom: 12px;
      span {
        font-size: 24px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #0394ff;
        position: relative;

        &::after {
          display: block;
          content: " ";
          position: absolute;
          bottom: -12px;
          width: 100%;
          height: 5px;
          background: #0394ff;
        }
      }
    }
  }
  .banner {
    width: 1243px;
    display: flex;
    // flex-wrap: wrap;
    height: 352px;
    margin-top: 27px;

    .prev {
      height: 100%;
      width: 90px;
      position: relative;
      i {
        display: block;

        line-height: 60px;
        text-align: center;
        font-size: 42px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        color: #d5d5d5;
      }
    }
    .next {
      height: 100%;
      position: relative;
      width: 90px;
      i {
        display: block;

        line-height: 60px;
        text-align: center;
        font-size: 42px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        color: #d5d5d5;
      }
    }
    .box {
      width: 240px;
      // height: 100%;
      background: #fff;
      img {
        width: 100%;
      }
      .box__bottom {
        padding: 12px 16px;
        .content {
          // display: flex;
          // flex-direction: column;
          // height: 157px;
          // justify-content: space-evenly;

          .title {
            font-size: 19px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-bottom: 45px;
          }
          .text {
            font-size: 16px;
            font-family: Helvetica-Regular, Helvetica;
            font-weight: 400;
            color: #999999;
            .reportText {
              margin-bottom: 5px;
            }
          }
          .count {
            font-size: 16px;
            font-family: Helvetica-Regular, Helvetica;
            font-weight: 400;
            color: #999999;
            padding: 0 12px;
          }
        }
      }
    }
  }
  .line {
    border-bottom: 1px solid #f0f0f0;
    margin-top: 24px;
    width: 100%;
  }
  .content {
    .baseBtn {
      display: flex;
      align-items: end;
      margin: 35px 0;
      .titleBox {
        width: 208px;
        height: 88px;
        display: flex;
        background: url("../assets/btn-background.png") no-repeat;
        background-position: 0 0;
        background-size: 100%;
        align-items: flex-end;
        .title {
          width: 180px;
          height: 53px;
          background: linear-gradient(
            90deg,
            #0092ff 0%,
            rgba(55, 182, 255, 0.8700000047683716) 100%
          );
          box-shadow: 0px 5px 9px 1px
            rgba(77.99999535083771, 191.00001901388168, 255, 0.25);
          border-radius: 39px 39px 39px 0px;
          border: none;
          font-size: 29px;
          font-weight: 800;
          color: #ffffff;
          font-family: PingFang Regular;
        }
      }

      .text {
        cursor: pointer;
        margin: 0 25px;
        font-size: 21px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
    .button {
      margin-top: 65px;
      margin-bottom: 207px;
      text-align: center;
      button {
        padding: 17px 64px;
        outline: none;
        background: #fff;
        border: 1px solid #0394ff;
        color: #0394ff;
        border-radius: 65px;
        font-size: 21px;
        &:hover {
          background: #0394ff;
          box-shadow: 0px 5px 24px 1px
            rgba(2.9999998211860657, 147.9999303817749, 255, 0.5099999904632568);
          color: #ffffff;
        }
      }
    }
  }
}
</style>